

































import {Vue, Component, Model, Prop, Watch} from 'vue-property-decorator';
import { debounce } from 'typescript-debounce-decorator';
import CompanyDTO from "@/dto/company/CompanyDTO";
import CompanyFilterDTO from "@/dto/company/CompanyFilterDTO";
import CompanyService from "@/services/CompanyService";
import {processError} from "@/utils/ComponentUtils";

@Component({})
export default class SimpleCompanySearch extends Vue {

  @Model('change', {type: CompanyDTO, default: null})
  private selectedCompany!: CompanyDTO | null;

  @Prop({default: "companies"})
  private name!: string

  @Prop({default: false})
  private required!: boolean;

  private filter = new CompanyFilterDTO({pageSize: 8});

  private companies: Array<CompanyDTO> = [];

  private showList = false;

  private dropUp = false;

  mounted(){
    if(this.selectedCompany){
      this.filter.searchQuery = this.selectedCompany.name + ' ' + this.selectedCompany.ein + ' ' + this.selectedCompany.email;
    }
  }

  @debounce(200)
  private search(){
    CompanyService.getByFilter(this.filter).then(
        ok => {
          this.companies = ok.data.data;
          this.showList = true;

          this.$nextTick(() => {
            this.updateDropDownPosition();
          });
        },
        err => processError(err, this)
    )
  }

  selectCompany(c: CompanyDTO){
    this.showList = false;
    this.filter.searchQuery = c.name + ' ' + c.ein + ' ' + c.email;
    this.$emit('change', c);
  }

  @Watch("selectedCompany")
  watchCompany(_new: CompanyDTO | null, old: CompanyDTO | null){
    if(_new == null){
      this.filter.searchQuery = null;
    }
  }

  private updateDropDownPosition() {

    const inputEl = this.$el.querySelector('input');

    const autocompleteEl = this.$refs.autocomplete as HTMLElement;
    if(!inputEl || !autocompleteEl) return;

    const inputRect = inputEl.getBoundingClientRect();

    const windowHeight = window.innerHeight;

    const spaceBelow = windowHeight - inputRect.bottom;

    const dropdownHeight = autocompleteEl.offsetHeight;

    this.dropUp = spaceBelow < dropdownHeight;
  }
}
